<template>
  <div v-if="forFacebookInApp">
    <div class="card-a bg-warning">{{ $t('views.help.google_login_for_facebook_in_app') }}</div>
    <CButton label="Copy" translated="true" icon="content-copy" @click="copyToClipboard('https://www.ydfinance.jp/#/login')" />
    <p v-if="copied" class="text-primary">{{ '[https://www.ydfinance.jp/#/login] copied!' }}</p>
  </div>
  <div v-else>
    <CMessage :messages="message.base" />
    <CInputText
      v-model="user.email"
      label="user.email"
      :errors="message.email"
    />
    <CInputPassword
      v-model="user.password"
      label="user.password"
      :errors="message.password"
    />
    <CButton
      label="login"
      :disabled="!agree_to"
      @click.prevent="submit"
    />
    <CLink
      label="can_not_login"
      :to="{name: 'reset_password'}"
    />
    <CLink
      label="has_not_registered"
      :to="{name: 'register'}"
    />
    <CButton
      icon="google"
      :label="$t('views.route.login_with_google')"
      translated
      :disabled="!agree_to"
      @click.prevent="login_with_google()"
    />
    <div class="card-a">
      <div>
        <p class="text-sm">
          {{ $t('views.help.agree_to') }}
        </p>
        <CLink
          :to="{name: 'terms'}"
          label="terms"
          target="_blank"
        />
        <CLink
          :to="{name: 'privacies'}"
          label="privacies"
          target="_blank"
        />
      </div>
      <div class="flex items-center">
        <input
          id="agree_to"
          v-model="agree_to"
          type="checkbox"
        >
        <label
          for="agree_to"
          class="ml-2"
        >{{ $t('views.route.agree_to') }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'

export default {
  name: "Login",
  data: function () {
    return {
      message: {},
      user: {
        email: '',
        password: ''
      },
      agree_to: true,
      forFacebookInApp: false,
      copied: false
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    }
  },
  created() {
    if(this.isLoggedIn) {
      this.$router.push({ name: 'home' });
    }
    if(this.$route.query.m == 'locked') {
      this.message = { base: this.$t('views.failure.locked') };
    }
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
      this.copied = true
    },
    login_with_google() {
      if (navigator.userAgent.match(/FBAN|FBAV/i)) {
        // Facebook in-app browser detected
        this.forFacebookInApp = true
        return
      }
      window.location.href='/api/v1/users/google_oauth'
    },
    submit() {
      ApiRequestor.post('users/sessions', {user: this.user}).then(
        response => {
          this.$store.dispatch("auth/login", response.data.jwt);
          ApiRequestor.pushRedirectUrl()
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  },
}
</script>