<template>
  <div v-if="forFacebookInApp">
    <div class="card-a bg-warning">{{ $t('views.help.google_login_for_facebook_in_app') }}</div>
    <CButton label="Copy" translated="true" icon="content-copy" @click="copyToClipboard('https://www.ydfinance.jp/#/register')" />
    <p v-if="copied" class="text-primary">{{ '[https://www.ydfinance.jp/#/register] copied!' }}</p>
  </div>
  <div v-else>
    <CStepper :steps="steps" />
    <CMessage :messages="message.base" />
    <div v-if="steps.current==0">
      <CInputText
        v-model="user.email"
        label="user.email"
        :errors="message.email"
      />
      <CInputPassword
        v-model="user.password"
        label="user.password"
        :errors="message.password"
      />
      <CInputPassword
        v-model="password_repeat"
        label="user.password_repeat"
        :errors="message.password_repeat"
      />
      <CButton
        label="register"
        :disabled="!agree_to"
        @click.prevent="submit"
      />
      <CLink
        label="has_registered"
        :to="{name: 'login'}"
      />
      <CButton
        icon="google"
        :label="$t('views.route.login_with_google')"
        translated
        :disabled="!agree_to"
        @click.prevent="login_with_google()"
      />
      <div class="card-a">
        <div>
          <p class="text-sm">
            {{ $t('views.help.agree_to') }}
          </p>
          <CLink
            :to="{name: 'terms'}"
            label="terms"
            target="_blank"
          />
          <CLink
            :to="{name: 'privacies'}"
            label="privacies"
            target="_blank"
          />
        </div>
        <div class="flex items-center">
          <input
            id="agree_to"
            v-model="agree_to"
            type="checkbox"
          >
          <label
            for="agree_to"
            class="ml-2"
          >{{ $t('views.route.agree_to') }}</label>
        </div>
      </div>
    </div>
    <div v-if="steps.current==1">
      <div class="card-a bg-warning">
        {{ $t('views.help.otp.generate') }}
      </div>
      <CInputText
        v-model="user.otp"
        label="user.otp"
        :errors="message.otp"
      />
      <CButton
        label="confirm"
        @click.prevent="confirm"
      />
      <CLink
        label="generate_otp"
        @click.prevent="generate_otp()"
      />
      <CMessage
        :messages="message.success"
        type="success"
      />
    </div>
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'

export default {
  name: "Register",
  data: function () {
    return {
      message: {},
      user: {
        email: '',
        password: '',
        otp: ''
      },
      agree_to: true,
      forFacebookInApp: false,
      copied: false,
      password_repeat: '',
      steps: {
        names: ['register.input', 'register.confirm'],
        current: 0
      }
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    }
  },
  created() {
    if(this.isLoggedIn) {
      this.$router.push({ name: 'home' });
    }
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
      this.copied = true
    },
    login_with_google() {
      if (navigator.userAgent.match(/FBAN|FBAV/i)) {
        // Facebook in-app browser detected
        this.forFacebookInApp = true
        return
      }
      window.location.href='/api/v1/users/google_oauth'
    },
    submit() {
      if(this.user.password != this.password_repeat) {
        this.message = { password_repeat: [this.$t('views.failure.password_not_matching')] }
        return
      }
      ApiRequestor.post('users/registration', {user: this.user}).then(
        response => {
          this.message = {}
          this.steps.current++
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    generate_otp() {
      ApiRequestor.post('users/otp', {user: {email: this.user.email}}).then(
        response => {
          this.message = { success: this.$t('views.success.generate_otp') }
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    confirm() {
      ApiRequestor.put('users/confirmation', {user: {email: this.user.email, otp: this.user.otp}}).then(
        response => {
          this.steps.current++
          this.$store.dispatch("auth/login", response.data.jwt);
          ApiRequestor.returnRedirectUrl()
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  },
}
</script>